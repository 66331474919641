@import url("https://use.typekit.net/sjg3eem.css");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  font-family: "commuters-sans", sans-serif;
  color: #333;
  overflow-x: hidden; }

.app {
  max-width: 1920px;
  margin: 0 auto; }

.header {
  background-color: white;
  height: 10rem;
  display: flex;
  justify-content: space-between;
  z-index: 1000000 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; }
  @media (max-width: 1500px) {
    .header {
      height: 8rem; } }
  @media (max-width: 1100px) {
    .header {
      height: 6rem; } }
  .header__logo {
    height: 9rem;
    margin-left: 4rem; }
    @media (max-width: 1500px) {
      .header__logo {
        height: 5rem;
        margin-left: 3rem;
        margin-top: 1.5rem; } }
    @media (max-width: 1100px) {
      .header__logo {
        height: 4rem;
        margin-left: 2rem;
        margin-top: 1rem; } }
    @media (max-width: 650px) {
      .header__logo {
        height: 3rem; } }
  @media (max-width: 850px) {
    .header__nav {
      display: none; } }
  .header__list {
    display: flex;
    justify-content: end;
    list-style-type: none;
    align-items: center;
    margin: 0 5rem; }
    @media (max-width: 1500px) {
      .header__list {
        margin: 0 3rem; } }
    @media (max-width: 1100px) {
      .header__list {
        margin: 0 2rem; } }
    .header__list-link {
      text-decoration: none;
      color: black;
      text-transform: uppercase; }
    .header__list-items {
      font-size: 1.8em;
      font-weight: 400;
      padding: 0.8rem 1.8rem;
      margin-top: 3rem;
      margin-bottom: 3rem; }
      @media (max-width: 1500px) {
        .header__list-items {
          font-size: 1.6em;
          font-weight: 400;
          padding: 0.8rem 1.4rem;
          margin-top: 2.5rem;
          margin-bottom: 2.5rem; } }
      @media (max-width: 1100px) {
        .header__list-items {
          font-size: 1.4em;
          font-weight: 400;
          padding: 0.7rem 1.2rem;
          margin-top: 1.6rem;
          margin-bottom: 2rem; } }
      .header__list-items:hover {
        cursor: pointer; }

.anchor {
  display: block;
  position: relative;
  top: -10rem;
  visibility: hidden;
  height: 0rem; }
  @media (max-width: 1500px) {
    .anchor {
      top: -8rem; } }
  @media (max-width: 1100px) {
    .anchor {
      top: -6rem; } }

.nav__list--collapsed {
  margin-top: 8rem;
  text-transform: uppercase; }
  @media (max-width: 650px) {
    .nav__list--collapsed {
      margin-top: 20rem; } }
  @media (max-width: 400px) {
    .nav__list--collapsed {
      margin-top: 8rem; } }

.nav__item--collapsed {
  list-style: none;
  margin: 2rem; }

.nav__link--collapsed:link, .nav__link--collapsed:visited {
  display: inline-block;
  font-size: 4rem;
  padding: 1rem 2rem;
  text-decoration: none;
  color: #ffffff;
  background-image: linear-gradient(120deg, transparent 0%, transparent 50%, white 50%);
  background-size: 240%;
  transition: all .4s; }
  @media (max-width: 650px) {
    .nav__link--collapsed:link, .nav__link--collapsed:visited {
      font-size: 2.5rem; } }
  .nav__link--collapsed:link:hover, .nav__link--collapsed:visited:hover {
    color: #0a0a0a;
    font-size: 5rem; }
    @media (max-width: 650px) {
      .nav__link--collapsed:link:hover, .nav__link--collapsed:visited:hover {
        font-size: 3rem; } }

.nav__link--collapsed:hover, .nav__link--collapsed:active {
  background-position: 100%;
  cursor: pointer;
  font-size: 6rem;
  transform: translateY(3px); }
  @media (max-width: 650px) {
    .nav__link--collapsed:hover, .nav__link--collapsed:active {
      font-size: 4rem; } }

.nav__collapsed {
  display: none;
  margin-left: auto;
  margin-top: 3rem;
  position: relative;
  text-align: center; }
  @media (max-width: 850px) {
    .nav__collapsed {
      display: inline-block; } }

.nav__nav {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1500;
  opacity: 0;
  width: 0;
  transition: all 0.8s cubic-bezier(0.86, 0, 0.07, 1); }

.nav__checkbox {
  display: none; }

.nav__checkbox:checked ~ .nav__background {
  transform: scale(80); }

.nav__checkbox:checked ~ .nav__nav {
  width: 100%;
  opacity: 1; }

.nav__button {
  background-color: #171717;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  position: fixed;
  top: 0.5rem;
  right: 2.75rem;
  z-index: 2000;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer; }

.nav__background {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
  position: fixed;
  top: 1rem;
  right: 3rem;
  background-image: radial-gradient(#1b1b1b, #0a0a0a);
  z-index: 1000;
  transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1); }

.nav__icon {
  position: relative;
  margin-top: 2.4rem; }
  .nav__icon, .nav__icon::before, .nav__icon::after {
    width: 2.5rem;
    height: 2px;
    background-color: #005cb9;
    display: inline-block; }
  .nav__icon::before, .nav__icon::after {
    content: "";
    position: absolute;
    left: 0;
    transition: all .2s; }
  .nav__icon::before {
    top: -0.8rem; }
  .nav__icon::after {
    top: 0.8rem; }

.nav__button:hover .nav__icon::before {
  top: -1rem; }

.nav__button:hover .nav__icon::after {
  top: 1rem; }

.nav__checkbox:checked + .nav__button .nav__icon {
  background-color: transparent; }

.nav__checkbox:checked + .nav__button .nav__icon::before {
  top: 0;
  transform: rotate(135deg); }

.nav__checkbox:checked + .nav__button .nav__icon::after {
  top: 0;
  transform: rotate(45deg); }

/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden; }

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 1.8em;
  border: none;
  outline: none;
  color: black;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  /* Important for vertical align on mobile phones */
  margin: 0;
  /* Important for vertical align on mobile phones */ }
  @media (max-width: 1500px) {
    .dropdown .dropbtn {
      font-size: 1.6em;
      font-weight: 400;
      padding: 0.8rem 1.4rem;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem; } }
  @media (max-width: 1100px) {
    .dropdown .dropbtn {
      font-size: 1.4em;
      font-weight: 400;
      padding: 0.7rem 1.2rem;
      margin-top: 1.6rem;
      margin-bottom: 2rem; } }

/* Add a red background color to navbar links on hover */
/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1; }

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left; }

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd; }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block; }

.landing__container {
  width: 100%;
  position: relative;
  height: 100vh;
  overflow: hidden; }

.landing__content {
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90vw;
  display: flex;
  flex-direction: column;
  margin-top: 2rem; }
  @media (max-width: 1350px) {
    .landing__content {
      margin-top: 4rem; } }
  @media (max-width: 700px) {
    .landing__content {
      margin-top: 6rem; } }
  .landing__content--brands {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 12rem; }
    @media (max-width: 1850px) {
      .landing__content--brands {
        margin: 5rem auto;
        width: 90%; } }
    @media (max-width: 700px) {
      .landing__content--brands {
        display: flex;
        flex-direction: column; } }
    @media (max-width: 500px) {
      .landing__content--brands {
        display: flex;
        flex-direction: column;
        width: 100%; } }
  .landing__content--brand {
    margin: 0 auto; }

.landing__img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }
  @media (max-width: 1500px) {
    .landing__img {
      margin-top: 8rem; } }
  @media (max-width: 1400px) {
    .landing__img {
      width: 150%;
      margin: 0 auto; } }
  @media (max-width: 1100px) {
    .landing__img {
      margin-top: 6rem; } }
  @media (max-width: 1000px) {
    .landing__img {
      width: 200%; } }
  @media (max-width: 750px) {
    .landing__img {
      width: 300%; } }
  @media (max-width: 525px) {
    .landing__img {
      width: 400%; } }
  @media (max-width: 400px) {
    .landing__img {
      width: 500%; } }
  .landing__img--brand-ct {
    height: 18rem;
    margin-top: -1rem; }
    @media (max-width: 1650px) {
      .landing__img--brand-ct {
        height: 18rem;
        margin-top: 1rem; } }
    @media (max-width: 1100px) {
      .landing__img--brand-ct {
        height: 12rem;
        margin-top: 2rem; } }
    @media (max-width: 700px) {
      .landing__img--brand-ct {
        height: 10rem;
        margin-top: 0rem; } }
    @media (max-width: 400px) {
      .landing__img--brand-ct {
        height: 6rem;
        margin-top: 0rem; } }
  .landing__img--brand-cc {
    height: 14rem;
    margin-top: 1.5rem; }
    @media (max-width: 1650px) {
      .landing__img--brand-cc {
        height: 15rem;
        margin-top: 2rem; } }
    @media (max-width: 1100px) {
      .landing__img--brand-cc {
        height: 10rem;
        margin-top: 3rem; } }
    @media (max-width: 700px) {
      .landing__img--brand-cc {
        height: 8rem; } }
    @media (max-width: 400px) {
      .landing__img--brand-cc {
        height: 5rem;
        margin-top: 1rem; } }
  .landing__img--brand-zip {
    height: 20rem;
    margin-top: -1rem; }
    @media (max-width: 1650px) {
      .landing__img--brand-zip {
        height: 20rem;
        margin-top: 1rem; } }
    @media (max-width: 1100px) {
      .landing__img--brand-zip {
        height: 15rem;
        margin-top: 2rem; } }
    @media (max-width: 700px) {
      .landing__img--brand-zip {
        height: 13rem;
        margin-top: 2rem; } }
    @media (max-width: 400px) {
      .landing__img--brand-zip {
        height: 8rem;
        margin-top: 1rem; } }
  .landing__img--brand-mor {
    height: 14rem;
    margin-top: -1rem; }
    @media (max-width: 1650px) {
      .landing__img--brand-mor {
        height: 15rem;
        margin-top: 1rem; } }
    @media (max-width: 1100px) {
      .landing__img--brand-mor {
        height: 12rem;
        margin-top: 2rem; } }
    @media (max-width: 700px) {
      .landing__img--brand-mor {
        height: 8rem;
        margin-top: 2rem; } }
    @media (max-width: 400px) {
      .landing__img--brand-mor {
        height: 5rem;
        margin-top: 1rem; } }
  .landing__img--brand-gr {
    height: 21rem;
    margin-top: -1.5rem; }
    @media (max-width: 1650px) {
      .landing__img--brand-gr {
        height: 20rem;
        margin-top: 0rem; } }
    @media (max-width: 1100px) {
      .landing__img--brand-gr {
        height: 15rem;
        margin-top: 3rem; } }
    @media (max-width: 700px) {
      .landing__img--brand-gr {
        height: 14rem;
        margin-top: 2rem; } }
    @media (max-width: 400px) {
      .landing__img--brand-gr {
        height: 8rem;
        margin-top: 1rem; } }

.landing__header {
  text-align: center;
  font-size: 5.5em;
  font-weight: 600; }
  @media (max-width: 1650px) {
    .landing__header {
      font-size: 4.5em; } }
  @media (max-width: 1250px) {
    .landing__header {
      font-size: 3.5em; } }
  @media (max-width: 700px) {
    .landing__header {
      font-size: 2.5em; } }

.coker__container {
  position: relative;
  width: 100%;
  min-height: 25rem; }

.coker__img {
  width: 100%;
  min-height: 45rem; }
  @media (max-width: 1050px) {
    .coker__img {
      object-fit: cover; } }

.coker__content--section {
  background-color: rgba(0, 0, 0, 0.8);
  height: 99.6%;
  width: 45rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  min-height: 45rem; }
  @media (max-width: 700px) {
    .coker__content--section {
      width: 100%; } }

.coker__content--box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 40rem;
  margin: 0 auto;
  margin-top: 3vw;
  margin-left: 5rem; }
  @media (max-width: 1500px) {
    .coker__content--box {
      margin-left: 3rem; } }
  @media (max-width: 700px) {
    .coker__content--box {
      width: 80%;
      margin: 2rem auto;
      height: 100%;
      margin-left: 4rem;
      align-items: center;
      text-align: center; } }
  @media (max-width: 400px) {
    .coker__content--box {
      width: 90%;
      margin: 2rem auto;
      margin-left: 1rem;
      text-align: center; } }

.coker__content--logo {
  width: 32rem; }
  .coker__content--logo-great-race {
    max-width: 26rem; }
    @media (max-width: 1500px) {
      .coker__content--logo-great-race {
        width: 20rem; } }
    @media (max-width: 1200px) {
      .coker__content--logo-great-race {
        width: 15rem; } }
    @media (max-width: 1100px) {
      .coker__content--logo-great-race {
        margin: 0 auto; } }
  @media (max-width: 1500px) {
    .coker__content--logo {
      width: 25rem; } }
  @media (max-width: 1100px) {
    .coker__content--logo {
      margin: 0 auto; } }

.coker__content--text {
  color: #dddddd;
  font-size: 1.6em;
  width: 90%;
  margin-top: 5rem;
  line-height: 2.5rem; }
  @media (max-width: 1500px) {
    .coker__content--text {
      margin-top: 3rem; } }
  @media (max-width: 1100px) {
    .coker__content--text {
      margin-top: 2rem; } }
  @media (max-width: 1000px) {
    .coker__content--text {
      font-size: 1.4em;
      line-height: 1.4em; } }
  @media (max-width: 1100px) {
    .coker__content--text {
      width: 95%; } }
  @media (max-width: 650px) {
    .coker__content--text {
      width: 100%; } }

.coker__content--link {
  color: #005cb9;
  font-size: 1.6em;
  margin-top: 5rem; }
  .coker__content--link:hover {
    cursor: pointer; }

.coker__content--button {
  font-size: 1.6em;
  margin-top: 5rem;
  padding: 1rem 3rem;
  font-weight: 700;
  color: #005cb9;
  background-color: white;
  text-decoration: none;
  border-radius: 0px;
  border: none; }
  @media (max-width: 1500px) {
    .coker__content--button {
      margin-top: 3rem; } }
  @media (max-width: 1100px) {
    .coker__content--button {
      margin-top: 2rem; } }
  @media (max-width: 700px) {
    .coker__content--button {
      margin: 4rem auto; } }
  @media (max-width: 650px) {
    .coker__content--button {
      grid-column: 1 / -1; } }
  .coker__content--button:hover {
    cursor: pointer; }

.news__container {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 10rem; }
  .news__container--cards {
    margin: 5rem 10%;
    display: flex;
    justify-content: space-around; }
    @media (max-width: 1400px) {
      .news__container--cards {
        justify-content: space-between;
        margin: 5rem 5%; } }
    @media (max-width: 1050px) {
      .news__container--cards {
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 15px; } }
    @media (max-width: 415px) {
      .news__container--cards {
        margin: 5rem auto; } }

.news__heading {
  color: black;
  font-weight: 600;
  margin: 5rem auto;
  font-size: 1.3em; }

.news__card {
  width: 20%;
  min-width: 25%;
  background-color: white;
  color: black;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.4);
  transition: all 0.2s ease-in-out; }
  @media (max-width: 1400px) {
    .news__card {
      min-width: 30rem; } }
  @media (max-width: 700px) {
    .news__card {
      min-width: 35rem; } }
  @media (max-width: 415px) {
    .news__card {
      min-width: 30rem; } }
  .news__card:hover {
    cursor: pointer;
    transform: scale(1.05); }
    @media (max-width: 650px) {
      .news__card:hover {
        transform: scale(1.02); } }
  .news__card--image {
    width: 100%;
    height: 20rem;
    object-fit: cover; }
  .news__card--text {
    padding: 2rem;
    padding-top: 1rem;
    text-align: start; }
  .news__card--header {
    font-size: 0.6em;
    font-weight: 800;
    text-transform: uppercase; }
  .news__card--sub {
    font-size: 0.4em;
    font-style: italic;
    font-weight: 800;
    text-transform: uppercase; }
  .news__card--company {
    color: #005cb9;
    font-size: 0.6em;
    margin-bottom: 1rem; }
  .news__card--date {
    color: #005cb9;
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .news__card--description {
    font-size: 0.4em; }

.news__banner {
  width: 100%;
  margin-top: -5rem; }

.news__modal {
  margin-top: 10rem; }

.news__blog {
  text-align: center;
  height: 80vh;
  width: 80%;
  border: 1px solid black;
  margin: 12rem auto;
  background-color: white;
  overflow-Y: scroll; }
  @media (max-width: 650px) {
    .news__blog {
      width: 95%; } }
  .news__blog--header {
    font-size: 3em;
    font-weight: 800;
    color: black;
    text-transform: uppercase;
    padding: 5rem;
    padding-bottom: 3rem; }
    @media (max-width: 1000px) {
      .news__blog--header {
        font-size: 2.5em;
        padding: 2rem; } }
    @media (max-width: 650px) {
      .news__blog--header {
        font-size: 2em; } }
  .news__blog--sub {
    font-size: 1.6em;
    font-style: italic;
    font-weight: 800;
    text-transform: uppercase;
    padding-bottom: 3rem; }
  .news__blog--company {
    color: #005cb9;
    font-size: 1.6em;
    margin-bottom: 1rem; }
  .news__blog--date {
    color: #005cb9;
    margin-top: 1rem;
    margin-bottom: 1rem; }
    @media (max-width: 1000px) {
      .news__blog--date {
        margin-bottom: 0rem; } }
  .news__blog--description {
    font-size: 1.6em;
    padding: 5rem 10rem;
    padding-top: 3rem;
    text-align: start; }
    @media (max-width: 1000px) {
      .news__blog--description {
        font-size: 1.4em;
        padding: 2rem;
        padding-top: 1rem; } }
    .news__blog--description > p {
      margin-top: 2rem; }

.contact__heading {
  text-align: center;
  color: black;
  margin-top: 2rem;
  font-size: 3em; }

.contact__locations {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 90%;
  margin: 5rem auto;
  justify-content: space-between;
  color: black; }
  @media (max-width: 1400px) {
    .contact__locations {
      margin-top: 3rem;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      width: 70%;
      justify-content: space-around;
      align-items: center; } }
  @media (max-width: 800px) {
    .contact__locations {
      margin-top: 2rem;
      grid-template-columns: repeat(1, 1fr);
      width: 70%;
      justify-content: space-around;
      align-items: center; } }
  .contact__locations--box {
    display: flex;
    flex-direction: column;
    justify-self: center;
    width: 25rem; }
    @media (max-width: 1300px) {
      .contact__locations--box {
        margin-top: 3rem; } }
    @media (max-width: 600px) {
      .contact__locations--box {
        align-items: center; } }
  .contact__locations--logo {
    height: 8rem;
    width: auto;
    margin-bottom: 2rem; }
    @media (max-width: 600px) {
      .contact__locations--logo {
        height: 6rem; } }
  .contact__locations--title {
    margin-top: 2rem;
    font-weight: 800; }
  .contact__locations > div > p {
    font-size: 1.4em; }

.contact__social {
  margin-top: 2rem;
  display: flex; }
  .contact__social--icon {
    margin-left: 2rem;
    height: 3rem;
    width: 3rem; }
    @media (max-width: 600px) {
      .contact__social--icon {
        height: 3rem;
        width: 3rem;
        margin-left: 1rem; } }

#first-icon {
  margin-left: 0rem; }

.footer__container {
  background-color: black;
  height: 10.5rem; }

.footer__text {
  padding-top: 8rem;
  text-align: center;
  color: #999; }
